
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import { formatAmount, formatTotalAmount } from '@/libs/utils'

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    customerId: Number,
    items: [] as Array<any>,
    item: {},
    visible: Boolean,
    readType: Boolean,
    changeItems: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        // console.log(row)
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();

        emit('updateTotal');
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.formData.UncollectedAmount = 0;
        table.formData.Amount = 0;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        table.formData.AccountsReceivable = null;
        if(!table.formData.AccountsReceivableId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇項目' })
        if (table.formData.AccountsReceivableId) {
          const temp = await model?.dispatch("accountsReceivable/find", table.formData.AccountsReceivableId)
          table.formData.AccountsReceivable = { Id: temp.Id, InvoiceNumber: temp.InvoiceNumber, Name: temp.Name };
        }
        if(table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          // table.formData.Ordinal = getNextOrdinal();
          var current = grid.value.getTableData().fullData;
          if(current.find((e:any) => e.AccountsReceivableId == table.formData.AccountsReceivableId))
            return CloudFun.send('error', { subject: '執行失敗', content: '銷貨項目已存在' });

          await grid.value.insert(table.formData);
        }
        table.reset();
        await grid.value.sort("Ordinal", 'asc');

        emit('updateTotal');
      },
      rules: {
        AccountsReceivableId: [{ required: true }],
      }
    })

    watch(() => props.aid, async(value) => {
      if(value) {
        console.log(props.data)
        if(props.data && props.data.length > 0) {
          table.data = props.data;
          table.data.forEach(async (e: any) => {
            if(e.AccountsReceivableId) {
              const temp = await model?.dispatch("accountsReceivable/find", e.AccountsReceivableId)
              e.AccountsReceivable = { InvoiceNumber: temp.InvoiceNumber, Name: temp.Name };
            }
          });
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "InvoiceNumber",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "InvoiceNumber",
          title: "銷貨單號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("accountsReceivable/find", value),
        query: params => {
          if(props.customerId) params.condition = new Condition("CustomerId", Operator.Equal, props.customerId).and(params.condition!);
          return model!.dispatch("accountsReceivable/queryC1", params)
        }
      }
    };

    const numberChange = async (data: any) => {
      if(data?.AccountsReceivableId) {
        try {
          const temp = await model?.dispatch("accountsReceivable/find", data.AccountsReceivableId)
          if (temp) {
            data.UncollectedAmount = temp.UncollectedAmount;
            data.Amount = temp.UncollectedAmount;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const TF = [{ label: "是", value: true }, { label: "否", value: false }];

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      numberChange,
      formatAmount,
      TF,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
